<template>
  <section class="app-ecommerce-details">
    <maintenance-add-new
      :is-add-new-maintenance-sidebar-active.sync="isAddNewMaintenanceSidebarActive" 
        :inMaintenanceId="inMaintenanceId"      
    />

    <desordre-list-add-new
      :is-add-new-desordre-sidebar-active.sync="isAddNewDesordreSidebarActive"
      @adddesordre='(indesordre) => addDesordre(indesordre)'  
      :composantsList="arrayComposants"
      :inDesordreData="inDesordreData"      
    />

    <inspectionmodal :multi="false"  class="mt-2" :inspection="inspection"/>
    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row>

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="2"
            class="mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
               
            </div>
          </b-col>  
          <b-col
            cols="12"
            md="6"
            class="mb-2 mb-md-0"
          >            
            <div class="d-flex align-items-left justify-content-left">
              <h4>{{ product.name }}</h4>
            </div>
            <div class="d-flex align-items-left justify-content-left">            
                  <b-card-text class="item-company mb-0">
                    <span>Type d'ouvrage : {{ product.typeName }}</span>
                  </b-card-text>
            </div>  
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0">
                  <span>Plateforme : {{ plateforme }}</span>
                </b-card-text>
            </div>      
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0">
                  <span>Localisation :</span>
                </b-card-text>
            </div>  
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0 ml-2">
                  <span>Long. : {{ product.center[0] }}</span>
                </b-card-text>
            </div>    
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0 ml-2">
                  <span>Lat. : {{ product.center[1] }}</span>
                </b-card-text>
            </div>                                          
          </b-col>  
          <b-col
            cols="12"
            md="4"
            class="mb-2 mb-md-0"
          >              
            <div class="d-flex align-items-right justify-content-right">
              <h4>
                IQOA : 
                <div class="containeriqoa">
                  <img v-if="product.iqoa === '1'" :src="require('@/assets/images/ficheiqoa/gb.png')" height="35px" width="35px"/>
                  <img v-if="product.iqoa === '2' || product.iqoa === '2E'" :src="require('@/assets/images/ficheiqoa/yellow.png')" height="35px" width="35px"/>
                  <img v-if="product.iqoa === '3' || product.iqoa === '3U'" :src="require('@/assets/images/ficheiqoa/red.png')" height="35px" width="35px"/>
                        
                    <div class="text">
                      {{ product.iqoa }}
                    </div>
                </div>
              </h4>
            </div>
               
              <div>
                <span  style="float:right">
                <b-button
                  variant="primary"
                  @click="gotoFiche"
                  class="ml-2 mt-2"
                >
                  <span class="text-nowrap">Voir le dossier d'ouvrage</span>
                </b-button></span>
              </div>                          
          </b-col>
        </b-row>
        <b-row>
          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="bar-button">
            <b-button-toolbar class="align-items-center justify-content-center">
              <b-button-group
              >                
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='synthese';"
                  autofocus="autofocus"
                >
                  Synthèse état
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='inspection';"
                >
                  Calendrier inspection
                </b-button>     
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='desordre';"
                >
                  Désordres
                </b-button>                             
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='maintenance';"
                >
                  Maintenances
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='evenement';"
                >
                  Evénements
                </b-button> 
                                                                         
              </b-button-group>
            </b-button-toolbar> 
            </div> 
            <div class="fichecontainer" style="width:100%;height:100%;">


                
                <div class="fichecontent" v-if="toogle == 'synthese'">
                 <b-row>
                   <b-col md=6>   
                    <b-card no-body>
                      <b-card-header>
                        <!-- title and subtitle -->
                        <div>
                          <b-card-title class="mb-1">
                            Evolution classe IQOA de l'ouvrage
                          </b-card-title>
                        </div>
                        <!--/ title and subtitle -->
                      </b-card-header>

                      <b-card-body>
                        <vue-apex-charts
                          type="line"
                          height="400"
                          :options="lineChartSimple.chartOptions"
                          :series="lineChartSimple.series"
                        />
                      </b-card-body>
                    </b-card>
                </b-col>
                <b-col md=6>
                    <b-card no-body>
                      <b-card-header>
                        <!-- title and subtitle -->
                        <div>
                          <b-card-title class="mb-1">
                            Evolution vétusté de l'ouvrage
                          </b-card-title>
                        </div>
                        <!--/ title and subtitle -->
                      </b-card-header>

                      <b-card-body>
                        <vue-apex-charts
                          type="line"
                          height="400"
                          :options="lineChartSimple.chartOptions2"
                          :series="lineChartSimple.series2"
                        />
                      </b-card-body>
                    </b-card>
                </b-col>
                </b-row>


              <b-card no-body>

                <vue-apex-charts
                  type="treemap"
                  height="400"
                  :options="chartOptions"
                  :series="treemapData"
                />
              </b-card>                


                </div>

               <div class="fichecontent" v-if="toogle == 'maintenance'">
                 
                  <div>
                    <hr/>
                    <h6 class="mb-2">Liste des maintenances  </h6>
                    <b-table
                      ref="refUserListTable"
                      class="position-relative"
                      :items="maintenanceList"
                      responsive
                      :fields="tableColumns"
                      primary-key="id"
                      show-empty
                      empty-text="Aucune maintenance trouvée"
                    >
                      <template #empty="scope">
                        <h4>Aucune maintenance trouvée</h4>
                      </template>
                      <template #emptyfiltered="scope">
                        <h4>Aucune maintenance trouvée</h4>
                      </template>
                      <!-- Column: User -->
                      <template #cell(nom)="data">
                          <b-link
                          @click="onMaintenanceEdit(data.item.id)"
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.item.nom }}
                          </b-link>
                      </template>

                      <!-- Column: Role -->
                      <template #cell(ouvrage)="data">
                        <div class="text-nowrap">
                          <span class="align-text-top text-capitalize">{{ data.item.ouvragelst }}</span>
                        </div>
                      </template>

                      <!-- Column: Status -->
                      <template #cell(date)="data">
                        <span class="align-text-top text-capitalize">     
                          {{ data.item.date }}
                        </span>
                      </template>

                      <!-- Column: Status -->
                      <template #cell(statut)="data">
                        <b-badge
                          pill
                          :variant="`light-${resolveUserStatusVariant(data.item.statut)}`"
                          class="text-capitalize"
                        >
                          {{ data.item.statut }}
                        </b-badge>
                      </template>

                      <!-- Column: Status -->
                      <template #cell(priorite)="data">
                        <b-badge
                          pill
                          :variant="`light-${resolveUserRoleVariant(data.item.priorite)}`"
                          class="text-capitalize"
                        >
                          {{ data.item.priorite }}
                        </b-badge>
                      </template>

                    </b-table>
                   
                    
                  </div>
                    <span style="color:red">(pour des raisons de consistance de la démo, cette liste n'est pas filtrée sur l'ouvrage en question )</span>
                </div>
                         
              <div class="fichecontent" style="height:500px" v-if="toogle == 'inspection'">
                 <b-col cols="12" style="height:450px">
                    <hr/>
                    <h6 class="mb-2">Liste des inspections  </h6>
                            <gantt class="left-container" 
                            :tasks="inspectionGanttList" 
                            ganttType="inspection"
                            @store="store = $event"
                            @showDetail="(inspectionId) => showInspectionFromId(Number(inspectionId))" 
                            ></gantt>
                   
                    
                  </b-col>
                    
                </div>
                <span  v-if="toogle == 'inspection'" style="color:red">(pour des raisons de consistance de la démo, cette liste n'est pas filtrée sur l'ouvrage en question )</span>

              <div class="fichecontent" v-if="toogle == 'desordre'">
                 
                  <div>
                    <hr/>
                    <h6 class="mb-2">Liste des désordres  </h6>
                    <b-table
                      ref="refUserListTable"
                      class="position-relative"
                      :items="desordreList"
                      responsive
                      :fields="tableColumnsDes"
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                    >

                      <!-- Column: User -->
                      <template #cell(type)="data">
                          <b-link
                            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.item.type }}
                          </b-link>
                      </template>

                      <template #cell(libelle)="data">
                          <b-link
                            @click="onDesordreEdit(data.item)"
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.item.libelle }}
                          </b-link>
                      </template>        

                      <!-- Column: Role -->
                      <template #cell(composants)="data">
                        <div class="text-nowrap">
                          <span class="align-text-top text-capitalize">{{ data.item.composants }}</span>
                        </div>
                      </template>

                      <!-- Column: Status -->
                      <template #cell(created)="data">
                        <span class="align-text-top text-capitalize">     
                          {{ data.item.created }}
                        </span>
                      </template>

                      <!-- Column: Status -->
                      <template #cell(status)="data">
                        <b-badge
                          pill
                          :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                          class="text-capitalize"
                        >
                          {{ data.item.status }}
                        </b-badge>
                      </template>



                    </b-table>
                   
                    
                  </div>
                    <span style="color:red">(pour des raisons de consistance de la démo, cette liste n'est pas filtrée sur l'ouvrage en question )</span>
                </div>

            <div class="fichecontent" v-if="toogle == 'evenement'">
                 
                  <div>
                    <hr/>
                    <h6 class="mb-2">Liste des événements                <span class="mb-1" style="float:right"><b-button variant="primary">
                <span class="text-nowrap">Ajouter un événement</span>
              </b-button></span></h6> 
                    <b-table
                      ref="refUserListTable"
                      class="position-relative"
                      :items="eventList"
                      responsive
                      :fields="tableColumnsEvt"
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                    >

                      <!-- Column: User -->
                      <template #cell(type)="data">
                          <b-link
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.item.type }}
                          </b-link>
                      </template>

                      <template #cell(libelle)="data">
                          <b-link
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.item.libelle }}
                          </b-link>
                      </template>        

  

                      <!-- Column: Status -->
                      <template #cell(created)="data">
                        <span class="align-text-top text-capitalize">     
                          {{ data.item.created }}
                        </span>
                      </template>




                    </b-table>
                   
                    
                  </div>
                    <span style="color:red">(pour des raisons de consistance de la démo, cette liste n'est pas filtrée sur l'ouvrage en question )</span>
                </div>

                
            </div>
          </b-col>
        </b-row>
      </b-card-body>         
    </b-card>
  </section>
</template>


<script>
import { ref } from '@vue/composition-api'
import router from '@/router'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import chartedit from '@/components/ChartEdit.vue'
import fileViewer from '@/components/FileViewer.vue' 
import VueApexCharts from 'vue-apexcharts'
import MaintenanceAddNew from '@/views/apps/maintenance/MaintenanceAddNew.vue'
import Gantt from '@/components/Gantt.vue';
import inspectionmodal from '@/views/apps/inspection/InspectionModal.vue'

import 'swiper/css/swiper.css'
import {
  BCard, BCardBody, BRow,BBadge, BCol, BImg, BCardText, BLink,BButtonToolbar, BButtonGroup, BButton, BDropdown, BDropdownItem, BAlert,BTable,BFormInput,BFormCheckbox,BCardHeader, BCardTitle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import VJstree from 'vue-jstree'
import vSelect from 'vue-select'
import Assettypes from '@/@fake-db/data/apps/assettype.js'

import FicheIqoa from '@/components/FicheComposant.vue'

import { $themeColors } from '@themeConfig'
import DesordreListAddNew from '@/views/apps/desordre/DesordreListAddNew.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    VueApexCharts,  
    BCardHeader,
    BCardTitle,
    BRow,
    BBadge,
    BCol,
    BImg,
    BCardText,
    BTable,
    BLink,
    BButtonToolbar,
    router,
    BButtonGroup,    
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    Swiper,
    vSelect,
    SwiperSlide,
    VJstree,
    chartedit,
    fileViewer,
    BFormInput,
    MaintenanceAddNew,
    BFormCheckbox,
    Gantt,
    FicheIqoa,
    inspectionmodal,
    DesordreListAddNew,
  },
  mounted() {
     // data

    this.$http.get('/apps/maintenance/maintenances',{ params: {q:{assets:[],priorite:[],type:[],queries:[], status:[]}} })
      .then(response => { 
        this.maintenanceList = response.data.maintenances 
  
        })
      .catch((e)=>{console.log(e)})  

   this.$http.get('/apps/desordre/desordres',{ params: {q:''} })
      .then(response => { 
        this.desordreList = response.data.desordres 
  
        })
      .catch((e)=>{console.log(e)})  

    this.$http.get('/apps/inspection/inspections',{ params: {q:{assets:[],priorite:[],type:[],queries:[], status:[]}} })
      .then(response => { 
        
        response.data.inspections.forEach(inspection => {
          let task = {id:inspection.id, text:(inspection.type+' '+inspection.ouvragelst), start_date:inspection.start_date,duration:inspection.duration, status:inspection.status, type:'inspection'}
          this.inspectionGanttList = {data:[...this.inspectionGanttList.data,task]}
          this.inspectionList.push(inspection)
        })  
        })
      .catch((e)=>{console.log(e)})  


    this.$http.get('/apps/patrimoine/asset/'+router.currentRoute.params.id)
      .then(response => { 
        this.product = response.data 
        this.product.typeName = this.Assettypes[this.product.type].name 
        this.$http.get('/apps/patrimoine/asset/'+this.product.parentid)
          .then(response => {
            this.plateforme = response.data.name
          })
          .catch((e)=>{console.log(e)}) 
 
        })
      .catch((e)=>{console.log(e)})       
      // data  
  },
  data() {
    return {
      document:{},
      maintenanceList:[],
      inspectionGanttList: {
        data: [],
        links: [],
      },
      inspectionList:[],
      desordreList:[],
      inMaintenanceId : -1,
      ficheId:'',
      plateforme:'',
      inspection:{},
      arrayComposants: ['GU156 - A142X - Corniche','GU156 - A143V - Chaussée et revêtement du tablier','GU156 - B51 - Mur de soutènement','GU156 - C1M59 - Bordure'],
      inDesordreData:{id:''} ,
      eventList:[
        {type:'Choc',libelle:'Choc camion travaux appui',created:'25/01/2021'},
        {type:'Incendie',libelle:'Incendit sur voie suite accident',created:'12/03/2012'},
        {type:'Glissement de terrain',libelle:'Glissement côté droit mur soutènement',created:'11/05/2004'},
        {type:'Choc',libelle:'Choc manoeuvre convoi exceptionnel',created:'11/02/2001'},
      ],
      product : {
        id: '',
        type: '',
        typeName:'',
        plateforme:'',
        name: '',
        parentid: 1,
        uoid:1,
        center: [],
        zoomlevel:14,  
      },

      tableColumns: [
        { key: 'priorite',label:"Priorité", sortable: true },
        { key: 'ouvragel',label:"Ouvrage", sortable: true },
        { key: 'nom',label:"Dénomination", sortable: true },
        { key: 'typeaction',label:"Type", sortable: true },
        { key: 'statut',label:"Statut", sortable: true },
        { key: 'date',label:"Date", sortable: true },
        { key: 'pilote',label:"Pilote", sortable: true },

        { key: 'desordre',label:"Désordre associé", sortable: true },
      ],

      tableColumnsDes : [
        { key: 'type',label:"Type", sortable: true },
        { key: 'libelle',label:"Libellé", sortable: true },
        { key: 'composants',label:"Composants", sortable: true },
        { key: 'iqoa',label:"IQOA", sortable: true },    
        { key: 'created',label:"Date", sortable: true },
        { key: 'status',label:"Statut", sortable: true },
        { key: 'inspection',label:"Inspection", sortable: true },  
      ]  ,    

      tableColumnsEvt : [
        { key: 'type',label:"Type", sortable: true },
        { key: 'libelle',label:"Libellé", sortable: true },
        { key: 'created',label:"Date", sortable: true }, 
      ]  ,       

      resolveUserStatusVariant: status => {
        if (status === 'A planifier') return 'warning'
        if (status === 'Terminé') return 'success'
        if (status === 'Planifié') return 'secondary'
        return 'primary'
      },

      resolveUserRoleVariant : role => {
        if (role === 'Faible') return 'primary'
        if (role === 'Moyenne') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'Haute') return 'danger'
        return 'primary'
      },

      toogle : "synthese",
      /* eslint-disable global-require */
      /* eslint-disable global-require */


         chartOptions: {
            legend: {
              show: false
            },
            chart: {
              height: 350,
              type: 'treemap'
            },
            title: {
              text: 'Vétusté par composant'
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '12px',
              },
              formatter: function(text, op) {
                return [text, op.value]
              },
              offsetY: -4
            },
            plotOptions: {
              treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                //distributed:true,
                colorScale: {
                  ranges: [
                    {
                      from: 0,
                      to: 20,
                      color: '#CD363A'
                    },
                    {
                      from: 20,
                      to: 100,
                      color: '#52B12C'
                    }
                  ],
                  //inverse : true
                }
              }
            }
          },

lineChartSimple: {
    series: [
      {
        data: ['1', '2', '2', '4', '2', '2', '2', '3', '3', '2', '2', '2', '1', '1', '1'],
      },
    ],
    series2: [
      {
        data: ['28', '35', '38', '73', '45', '51', '48', '55', '57', '51', '42', '38', '41', '25', '27'],
      },
    ],    
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.success],
      },
      colors: [$themeColors.success],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },

      xaxis: {
        categories: [
          '2008',
          '2009',
          '2010',
          '2011',
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
        ],
      },
      yaxis: {
    labels: {
      formatter: function(val) {
        if (val == 3) 
        return "2E"
        else if (val == 4)
        return "3"
        else if (val==5)
        return "3U"
        return val.toFixed(0)
      }
    },        
    min: 0,
    max: 5,
    tickAmount: 5,      
        categories: [
          '1',
          '2',
          '2E',
          '3',
          '3U',
        ],
      },
    },
    chartOptions2: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.primary],
      },
      colors: [$themeColors.primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },

      xaxis: {
        categories: [
          '2008',
          '2009',
          '2010',
          '2011',
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
        ],
      },
    },

  },


treemapData: [{ 
    name:"Equipements sur ouvrage", 
    data :[
      {"x": "Chaussée", "y": 45, "vétusté": 1},
      {"x": "Trottoirs et bordures", "y": 15, "vétusté": 2},
      {"x": "Dispositifs de retenue", "y": 12, "vétusté": 2},
      {"x": "Corniches", "y": 13, "vétusté": 3},
      {"x": "Dispositifs d'évacuation des eaux", "y": 18, "vétusté": 2},
      {"x": "Joints de chaussée et de trottoirs", "y": 13, "vétusté": 5},
      {"x": "Autres équipements", "y": 22, "vétusté": 1},]
      },
    {
    name:"Equipements sous ouvrage",
    data: [  
      {"x": "Chaussée de la voie franchie", "y": 35, "vétusté": 1},
      {"x": "Trottoirs et bordures", "y": 6, "vétusté": 3},
      {"x": "Dispositifs de retenue", "y": 18, "vétusté": 2},
      {"x": "Dispositifs d'évacuation des eaux", "y": 21, "vétusté": 5},]
      },
    {
      name:"Elements de protection",
      data:[
      {"x": "Etanchéité du tablier", "y": 27, "vétusté": 2},]
    },
    {
      name:"Tablier",
      data:[
      {"x": "Dalle", "y": 45, "vétusté": 2},
      {"x": "Défauts d'aspect de la dalle", "y": 38, "vétusté": 3},]
    }
    ],
          
    }
  }, 

  setup() {

    const ficheId = ref('')

      const isAddNewMaintenanceSidebarActive = ref(false)
       const isAddNewDesordreSidebarActive = ref(false)

    return {
      Assettypes,
      ficheId,
      isAddNewMaintenanceSidebarActive,
      isAddNewDesordreSidebarActive,
    }
  },

  methods: {
   
   gotoFiche: function() {
     this.$router.push({ path: '/apps/patrimoine/fiche/'+this.product.id})
   },
 
    itemClick: function (node) {
      this.document = node.model
    },     
    onMaintenanceEdit: function (maintenanceId) { 
       this.inMaintenanceId = maintenanceId     
       this.isAddNewMaintenanceSidebarActive = true

    },  

    onDesordreEdit: function (desordre) { 
       this.inDesordreData = desordre     
       this.isAddNewDesordreSidebarActive = true

     },     

    showInspectionFromId: function(id) {
      let inspection = this.inspectionList.find(a => a.id === id)
      this.showInspection(inspection)
    },

    showInspection: function(inspection) {
      this.inspection = inspection
      this.$root.$emit('bv::show::modal','modal-inspection')
    },

    onFetchData: function () { 
    },   
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-patrimoine-details.scss";
div.minimalistBlack {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.minimalistBlack .divTableCell, .divTable.minimalistBlack .divTableHead {
  padding: 5px 4px;
}
.divTable.minimalistBlack .divTableBody .divTableCell {
  font-size: 13px;
}
.divTable.minimalistBlack .divTableRow:nth-child(even) {
  background: #D0E4F5;
}
.divTable.minimalistBlack .divTableHeading {
  background: #7367F0;
  background: -moz-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: -webkit-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: linear-gradient(to bottom, #968df4 0%, #8176f1 66%, #7367F0 100%);
}
.divTable.minimalistBlack .divTableHeading .divTableHead {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: left;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
}
/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}

.swiper-navigations {
  max-width:900px;
}
.swiper-slide .img {
  max-width:900px;
}
.containeriqoa {
    display: inline-block;
    position: relative;
}

.containeriqoa .text {

    z-index: 1;
    position: absolute;
    text-align: center;
    font-family: Georgia;
    font-size: large;
    font-weight: bold;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 10%;
    color: rgb(97, 67, 207);
    width: 70%;
}

  .container {
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .gantt_task_cell {
    width: 35px;
  }
</style>
